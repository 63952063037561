<template>
  <div class="question">
    <div class="banner">
      <img src="~/static/activity/background.png" alt="" />
    </div>
    <div class="content">
      <div class="title">
        {{ detail.name }}
      </div>
      <ul class="list">
        <li v-for="(item, index) in list" :key="item.id">
          <div class="box" v-if="item.type == 1">
            <div class="name">{{ index + 1 + "、" + item.describe }}</div>
            <el-radio-group v-model="item.name">
              <el-radio
                :label="n.id"
                v-for="(n, i) in item.trainQuestionnaireOptionOutDtoList"
                :key="i"
              >
                {{ n.describe }}
              </el-radio>
            </el-radio-group>
          </div>
          <div class="box" v-if="item.type == 2">
            <div class="name">{{ index + 1 + "、" + item.describe }}</div>
            <el-input
              v-model="item.name"
              type="textarea"
              :autosize="{ minRows: 6, maxRows: 6 }"
              placeholder="请以文字描述方式留下您在网站及APP的使用中所遇到的问题"
            ></el-input>
          </div>
        </li>
      </ul>
      <div class="btn">
        <el-button type="primary" @click="submit">立即提交</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "question",
  data() {
    return {
      detail: [],
      list: [],
      questionnaireList: [],
    };
  },
  created() {
    this.init();
  },
  methods: {
    submit() {
      this.questionnaireList = [];
      this.list.forEach((n, i) => {
        if (n.name) {
          if (n.type == 1) {
            this.questionnaireList.push({
              matterId: n.id,
              optionId: n.name,
              questionnaireId: this.detail.id,
            });
          } else {
            this.questionnaireList.push({
              matterId: n.id,
              questionnaireId: this.detail.id,
              remark: n.name,
            });
          }
        } else{
          alert("您有题目未完成！");
          throw new Error('您有题目未填写')
        }
      });
         //提交数据
          this.$api
            .saveTrainQuestionnaire({
              questionnaireList: this.questionnaireList,
            })
            .then((res) => {
              if (res.data.success) {
                this.$message.success(res.data.msg);
              } else {
                this.$message.error(res.data.msg);
              }
            });
    },
    async init() {
      let questionnaireId = this.$route.query.id;
      let res = await this.$api.etTrainQuestionnaire({
        questionnaireId: questionnaireId,
      });
      if (res.data.success) {
        this.detail = res.data.data;
        this.list = this.detail.trainQuestionnaireMatterOutDtoList;
      }
    },
  },
};
</script>

<style scoped lang="less">
.question {
  min-height: 100vh;
  background: white;
img{
  width: 100%;
}
  .content {
    width: 840px;
    margin: 0 auto;
    padding: 48px 0;

    .title {
      margin-left: 24px;
      font-size: 24px;
      margin-bottom: 22px;
    }

    .list {
      padding: 30px 24px;
      box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.06);
      border-radius: 6px;
      li {
        .box {
          padding-bottom: 48px;

          .name {
            color: #66716c;
            margin-bottom: 20px;
          }
        }

        .box:last-child {
          margin-bottom: 0;
        }
      }
    }

    .btn {
      text-align: center;
      margin-top: 48px;
      .el-button {
        width: 336px;
      }
    }
  }
}
</style>